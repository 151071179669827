export default defineNuxtRouteMiddleware(async () => {
  const nuxtApp = useNuxtApp();

  const ticketToken = useCookie("AW_TICKET_TOKEN", {
    expires: new Date("2030-01-01"),
  });

  if (!ticketToken.value) {
    return navigateTo("/auth/login");
  }

  try {
    await nuxtApp.$ticket.account.introspectToken(ticketToken.value);
  } catch {
    ticketToken.value = null;
    return navigateTo("/auth/login");
  }
});
